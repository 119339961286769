@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

body {
  @apply font-raleway;
}
.nav-link {
  @apply text-white font-normal text-sm hover:opacity-80  hover:text-indigo-400 transition duration-300 ease-out whitespace-nowrap;
}

.active {
  @apply !text-indigo-500;
}

.p-toast-message-error {
  @apply !bg-[#ffe7e6] p-4 rounded-md text-[#ff5757] m-2;
}

.p-toast-message-content svg {
  @apply mr-2 mt-1;
}

@keyframes moveBackground {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
